import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import Modal from 'react-modal';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faTrash, faExclamation, faInfo } from '@fortawesome/free-solid-svg-icons'


// Pages
import Navigation from '../Navigation/Navigation';
import Overview from '../Overview/Overview';
import Landing from '../Landing/Landing'

import SignIn from '../SignIn/SignIn';

// css
import './app.css'

//Constants
import * as ROUTES from '../../constants/routes';
import { auth, functions } from '../../services/firebase';

// Modal styling
const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

Modal.setAppElement('#root')


// font awesome library
library.add(faCheckSquare, faCoffee, faTrash, faExclamation, faInfo);

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            user: null,
            modalIsOpen: false,
            messageValue: '',
        }
    
    }


    componentDidMount() {
        let that = this
        auth.onAuthStateChanged((user) => {
            if (user) {
                // console.log('Inside onAuthStateChanged, user exists, ', user)
                that.setState({ loading: false, user })
            } else {
                // auth().signInAnonymously()
                // console.log('Inside onAuthStateChanged, user didnt exist, ', user)
                that.setState({ user: null })
            }
          });


    }

    componentDidUpdate() {
        // console.log('Component did update, user: ', this.state.user)
    }


    handleChange = (event) => {
        this.setState({
            messageValue: event.target.value,
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.handleSubmitMessage(this.state.messageValue);
        
    }

    handleSubmitMessage(message) {
        let sendMessage = functions.httpsCallable('receiveCustomMessage');

        sendMessage({body: message}).then((result) => {
            // console.log(result)
            if (result.data.status === 'success') {
                this.closeModal()
                return
            }
        }).catch((error) => {
            //there's been an error. oops doErrorHandle
            console.log(error)
        })
    }

    // Modal controls
    openModal = () => {
        this.setState({modalIsOpen: true});
    }
    closeModal = () => {
        this.setState({modalIsOpen: false, messageValue: ''});
    }



    render() {
        return  (

            <Router>
                <div className="app-container">
                    <Navigation user={this.state.user} openSubmitMessage={this.openModal} />

                    {
                        !this.state.user ? <SignIn /> :
                            <div className="body-container">
                                <Route exact path={ROUTES.LANDING} component={Landing}><Redirect to='/overview' /></Route>
                                <Route path={ROUTES.OVERVIEW} component={Overview}  />
                                <Route path={ROUTES.OVERVIEW1} render={() => <Overview tag='1'/>}  />
                                <Route path={ROUTES.OVERVIEW2} render={() => <Overview tag='2'/>}  />
                                <Route path={ROUTES.OVERVIEW3} render={() => <Overview tag='3'/>}  />
                            </div>
                    }



                </div>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={modalStyles}
                    contentLabel='Create a message'
                >
                    <div className="modal-top-bar">
                        <div className="modal-title">
                            <h2>Create a Message</h2>
                        </div>
                        <button onClick={this.closeModal}>close</button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.handleSubmit}>
                            <textarea name="newMessage" type="text" onChange={this.handleChange} value={this.state.value}/>
                            <button type="submit" value="submit">Submit</button>
                        </form>
                    </div>
                </Modal>

            </Router>
        )
    }
}



export default App;


// function App() {
//   return (
//     <Router>
//         <div>
//             <Navigation />

//             <Route exact path={ROUTES.LANDING} component={Landing}/>
//             <Route path={ROUTES.SIGN_IN} component = {SignInPage} />
//             <Route path={ROUTES.OVERVIEW} component = {Overview} />
//         </div>
      
//     </Router>
//   );
// }