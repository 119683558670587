import React, { Component } from 'react';

import { db, firestore } from '../../services/firebase';


import MessageItem from '../MessageItem/MessageItem'

import './overview.css'

class Overview extends Component {
    constructor(props) {
        super(props);
        this.handleChangeTag = this.handleChangeTag.bind(this);
        this.handleHideMessage = this.handleHideMessage.bind(this);
        this.handleBlockUser = this.handleBlockUser.bind(this);
        this.state = {
            messages: [],
            readError: null,
        }
    }

    async componentDidMount() {
        this.setState({ readError: null })

        // console.log('props: ', this.props)

        if (this.props.tag) {
            try {
                db.collection('messages').orderBy('received', 'desc').where('hidden', '==', false).where('tag', '==', this.props.tag || '').onSnapshot((querySnapshot) => {
                        let messages = [];
                        querySnapshot.forEach((doc) => {
                            messages.push(doc.data());
                        });
                        this.setState({messages});
                    })
                } catch (error) {
                    this.setState({readError: error.message})
                }
        } else {
            try {
                db.collection('messages').orderBy('received', 'desc').where('hidden', '==', false).onSnapshot((querySnapshot) => {
                        let messages = [];
                        querySnapshot.forEach((doc) => {
                            messages.push(doc.data());
                        });
                        this.setState({messages});
                    })
                } catch (error) {
                    this.setState({readError: error.message})
                }
        }
        
    }

    async handleHideMessage(message) {
        // console.log('hide message was called, ', message)
        db.collection('messages').doc(message.id).set(
            {hidden: true},
            {merge: true}
        )
        .catch(function(error) {
            console.log('Error writing doc, ', error)
        })
    }

    async handleBlockUser(message) {
        var confirmRes = window.confirm('Confirm you wish to block this number');
        if (confirmRes) {
            this.handleHideMessage(message).then(
                db.collection('settings').doc('blocked-users').update({
                    numbers: firestore.FieldValue.arrayUnion(message.from)
                })
            )
        } else {
            return;
        }

    }

    async handleChangeTag(id, tag) {
        console.log(`received tag change request for id ${id} and tag ${tag}`)
        let messagesCopy = this.state.messages;
        let statedMessage = messagesCopy.find(el => el['id'] === id);
        if (statedMessage['tag'] !== tag) {
            db.collection('messages').doc(id).set(
                {tag: tag},
                {merge: true}
            )
            .catch(function(error) {
                console.log('Error writing doc, ', error)
            })           
        } else {
            db.collection('messages').doc(id).set(
                {tag: ''},
                {merge: true}
            )
            .catch(function(error) {
                console.log('Error writing doc, ', error)
            })            
        }
    }

    componentDidUpdate() {
        // console.log('Update, new message state it:', this.state.messages)
    }

    render() {
        let msgs = this.state.messages;
        let msgArray = [];

        msgs.forEach(message => {
            msgArray.push(<MessageItem 
                data={message} 
                key={message.id} 
                hideMessage={this.handleHideMessage.bind(this, message)}
                blockUser={this.handleBlockUser.bind(this, message)}
                changeTag={this.handleChangeTag}

                />)
            
        })

        // console.log('overview will send: ', this.state.messages)
        let messagesToSend = this.state.messages;
        return (
             <div className="overview-container">
                 {/* <MessageItem />  */}

                 {msgArray}

             </div>
            

        )
    }
} 

 
export default Overview;