import React from 'react';
import { Link } from 'react-router-dom';
 
import './navigation.css'

import * as ROUTES from '../../constants/routes';
import { useEffect } from 'react';
 
const Navigation = (props) => {



  useEffect(() => {
    
    // console.log(`Nav says the current user is ${props.user}`)

  })

  return (
    <div className="flex-container">
        {/* <div className="flex-item">
          <Link to={ROUTES.LANDING}>Home</Link>
        </div> */}
        <div className="flex-item">
          <Link to={ROUTES.OVERVIEW} className="nav-link">Overview</Link>
        </div>
        <div className="flex-item">
          <Link to={ROUTES.OVERVIEW1} className="nav-link">Green</Link>
        </div>
        <div className="flex-item">
          <Link to={ROUTES.OVERVIEW2} className="nav-link">Blue</Link>
        </div>
        <div className="flex-item">
          <Link to={ROUTES.OVERVIEW3} className="nav-link">Orange</Link>
        </div>
        {/* <div className="flex-item">
          {props.user && props.user.uid}
        </div> */}
        <div className="flex-item hide-small">
          <button onClick={props.openSubmitMessage}>Submit a Message</button>
        </div>
    </div>
  )
};
 
export default Navigation;