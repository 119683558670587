import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactTooltip from 'react-tooltip';

import './messageItem.css';

class MessageItem extends Component {
    constructor(props) {
        super(props)
        this.handleTagChange = this.handleTagChange.bind(this)
    }

    handleTagChange(e) {
        let selectedTag = e.target.getAttribute('tagid');
        this.props.changeTag(this.props.data.id, selectedTag)
    }

    


    render() {

        let from = '';

        if (this.props.data.filterFrom) {
            from = '***' + this.props.data.filterFrom;
        } else {
            from = '*******'
        }

        let tagClass =''

        if (this.props.data.tag) {
            switch (this.props.data.tag) {
                case '1':
                    tagClass = 'tagged1';
                    break;
                case '2':
                    tagClass = 'tagged2';
                    break;
                case '3':
                    tagClass = 'tagged3';
                    break;
                default:
                    tagClass = '';
            }
        }

        const jsTime = this.props.data.received.toDate().toLocaleTimeString('en-GB');
        const jsDate = this.props.data.received.toDate().toLocaleDateString('en-GB', {weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric'});


        let smsItem = (
            <div className='messageItem'>
                <div className='message-top-bar'>
                    <div className='message-phone-number'>{from}</div>
                    <div className='message-date-time'><em data-tip={jsDate}>{jsTime}</em></div>
                    <div className='message-flag-report' title="Block this number" onClick={this.props.blockUser}><FontAwesomeIcon icon="exclamation" ></FontAwesomeIcon></div>
                </div>
                <div className={`message-body ${tagClass}`}>{this.props.data.body}</div>
                <div className='message-toolbar'>
                    <button className='message-toolbar-flag1 default-font' tagid='1' onClick={this.handleTagChange}>Green</button>
                    <button className='message-toolbar-flag2 default-font' tagid='2' onClick={this.handleTagChange}>Blue</button>
                    <button className='message-toolbar-flag3 default-font' tagid='3' onClick={this.handleTagChange}>Orange</button>
                    <button className='message-toolbar-bin default-font' title='Hide this message' onClick={this.props.hideMessage}><FontAwesomeIcon icon="trash" /></button>
                </div>
                <ReactTooltip effect='solid'/>
            </div>
        )

        let pushItem = (
            <div className='messageItem'>
                <div className='message-top-bar'>
                    <div className='message-phone-number'><strong>Message</strong></div>
                    <div className='message-date-time'><em data-tip={jsDate}>{jsTime}</em></div>
                    {/* <div className='message-flag-report'><FontAwesomeIcon icon="exclamation" ></FontAwesomeIcon></div> */}
                </div>
                <div className='message-body-container'>
                    <div className='message-push push'><FontAwesomeIcon className="i-icon" icon="info" /></div>
                    <div className={`message-body ${tagClass} push`}>{this.props.data.body}</div>
                </div>
                <div className='message-toolbar'>
                    <button className='message-toolbar-flag1 default-font' tagid='1' onClick={this.handleTagChange}>Green</button>
                    <button className='message-toolbar-flag2 default-font' tagid='2' onClick={this.handleTagChange}>Blue</button>
                    <button className='message-toolbar-flag3 default-font' tagid='3' onClick={this.handleTagChange}>Orange</button>
                    <button className='message-toolbar-bin default-font' title='Hide this message' onClick={this.props.hideMessage}><FontAwesomeIcon icon="trash" /></button>
                </div>
                <ReactTooltip effect='solid'/>
            </div>
        )

        let messageItem;


        if (this.props.data.type === 'Push') {
            messageItem = pushItem;
        } else {
            messageItem = smsItem;
        }

        // console.log('MessageItem will render ', messageItem)


        return (

            <div>{ messageItem }</div>
            
        )
    }
}


export default MessageItem;