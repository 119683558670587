import React, {useState} from "react";
import { Link } from 'react-router-dom';

import { auth } from '../../services/firebase';

import './signIn.css';

const SignIn = () => {
    const email = 'av@fluidproductions.co.uk'
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const signInWithEmailAndPasswordHandler = (event,email, password) => {
                event.preventDefault();
                auth.signInWithEmailAndPassword(email, password).catch(error => {
                    setError('Error signing in');
                    console.error('Error signing in', error);
                })

    };

      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;

          if(name === 'userPassword'){
            setPassword(value);
          }
      };

  return (
    <div className="sign-in-container">
      <h1 className="">Sign In</h1>
      <div className="">
        {error !== null && <div className = "">{error}</div>}
        <form className="">
          <label htmlFor="userPassword" className="si-form-element">
            Password:
          </label>
          <input
            type="password"
            className="si-form-element"
            name="userPassword"
            value = {password}
            placeholder=""
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          <button className="si-form-element" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            Sign in
          </button>
        </form>

      </div>
    </div>
  );
};
export default SignIn;