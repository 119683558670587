import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCaj-IMwYByJrjeMVKuEQ4QwOrcuc_Kdv4",
    authDomain: "message-display.firebaseapp.com",
    databaseURL: "https://message-display.firebaseio.com",
    projectId: "message-display",
    storageBucket: "message-display.appspot.com",
    messagingSenderId: "755259616626",
    appId: "1:755259616626:web:921186e93dbe24693e81eb"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();

export const firestore = firebase.firestore;

export const functions = firebase.functions();